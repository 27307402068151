import React from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import NavigationLinks from '../components/navigation-links'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Bomba Stream</title>
        <meta property="og:title" content="Bomba Stream" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/07427ab8-de86-4d40-bf40-f0e2da2715f8/eafd24a7-8f21-4c58-89df-b2e23c19ba08?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <img alt="image" src="/logo-1500h.png" className="home-image" />
      <header data-role="Header" className="home-header">
        <div className="home-nav">
          <NavigationLinks rootClassName="rootClassName15"></NavigationLinks>
        </div>
        <img alt="image" src="/logo-1500h.png" className="home-image01" />
        <div className="home-container01">
          <a
            href="https://www.instagram.com/bombastream/"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link"
          >
            <img
              alt="pastedImage"
              src="/external/pastedimage-yx6p-200h.png"
              className="socials"
            />
          </a>
          <a
            href="https://t.me/bombastream"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link1"
          >
            <img
              alt="pastedImage"
              src="/external/pastedimage-chmtf-200h.png"
              className="home-pasted-image1 socials"
            />
          </a>
          <a
            href="https://www.youtube.com/c/BOMBABOOM"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link2"
          >
            <img
              alt="pastedImage"
              src="/external/pastedimage-pxsk-200h.png"
              className="home-pasted-image2 socials"
            />
          </a>
          <a
            href="https://discord.com/invite/eC3DKuh"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link3"
          >
            <img
              alt="pastedImage"
              src="/external/pastedimage-c08s-200h.png"
              className="home-pasted-image3 socials"
            />
          </a>
        </div>
        <div data-role="BurgerMenu" className="home-burger-menu">
          <svg viewBox="0 0 1024 1024" className="home-icon">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-role="MobileMenu" className="home-mobile-menu">
          <div className="home-nav1">
            <div className="home-container02">
              <img alt="image" src="/logo-1500h.png" className="home-image02" />
              <div
                data-role="CloseMobileMenu"
                className="home-close-mobile-menu"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon02">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <NavigationLinks rootClassName="rootClassName16"></NavigationLinks>
          </div>
          <div className="home-icon-group">
            <svg viewBox="0 0 950.8571428571428 1024" className="home-icon04">
              <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
            </svg>
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
            <svg viewBox="0 0 602.2582857142856 1024" className="home-icon08">
              <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
            </svg>
          </div>
          <div className="home-container03">
            <a
              href="https://www.instagram.com/bombastream/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link4"
            >
              <img
                alt="pastedImage"
                src="/external/pastedimage-yx6p-200h.png"
                className="home-pasted-image4"
              />
            </a>
            <a
              href="https://t.me/bombastream"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link5"
            >
              <img
                alt="pastedImage"
                src="/external/pastedimage-chmtf-200h.png"
                className="home-pasted-image5"
              />
            </a>
            <a
              href="https://www.youtube.com/c/BOMBABOOM"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link6"
            >
              <img
                alt="pastedImage"
                src="/external/pastedimage-pxsk-200h.png"
                className="home-pasted-image6"
              />
            </a>
            <a
              href="https://discord.com/invite/eC3DKuh"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link7"
            >
              <img
                alt="pastedImage"
                src="/external/pastedimage-c08s-200h.png"
                className="home-pasted-image7"
              />
            </a>
          </div>
        </div>
      </header>
      <div className="home-container04">
        <div className="home-players-container main-container">
          <div className="home-container05 tabs">
            <div className="home-container06 tab-titles">
              <div className="home-trovo-btn tab-title">
                <img
                  alt="image"
                  src="/icons8-trovo.svg"
                  className="btn-image"
                />
                <span className="home-text">Trovo</span>
              </div>
              <div className="home-twitch-btn tab-title">
                <img
                  alt="image"
                  src="/icons8-twitch.svg"
                  className="btn-image"
                />
                <span className="home-text01">Twitch</span>
              </div>
              <div className="home-youtube-btn tab-title">
                <img
                  alt="image"
                  src="/icons8-youtube.svg"
                  className="btn-image"
                />
                <span className="home-text02">YouTube</span>
              </div>
              <div className="home-vkplay-btn tab-title">
                <img alt="image" src="/icons8-vk.svg" className="btn-image" />
                <span className="home-text03">VkPlay</span>
              </div>
            </div>
            <div className="home-container07 tab-contents">
              <div className="home-trovo-iframe tab-content">
                <iframe
                  src={process.env.REACT_APP_TROVO_IFRAME_PLAYER_URL}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                  allowFullScreen="true"
                  className="home-iframe"
                ></iframe>
              </div>
              <div className="home-twitch-iframe tab-content">
                <iframe
                  src={process.env.REACT_APP_TWITCH_IFRAME_PLAYER_URL}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                  loading="lazy"
                  allowFullScreen="true"
                  className="home-iframe1"
                ></iframe>
              </div>
              <div className="home-youtube-iframe tab-content">
                <iframe
                  src={process.env.REACT_APP_YOUTUBE_IFRAME_PLAYER_URL}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                  loading="lazy"
                  allowFullScreen="true"
                  className="home-iframe2"
                ></iframe>
              </div>
              <div className="home-vkplay-iframe tab-content">
                <iframe
                  src={process.env.REACT_APP_VK_IFRAME_PLAYER_URL}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
                  loading="lazy"
                  allowFullScreen="true"
                  className="home-iframe3"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className="home-chats-container main-container">
          <div className="home-container08 tabs">
            <div className="home-container09 tab-titles">
              <div className="home-trovo-btn1 tab-title">
                <img
                  alt="image"
                  src="/icons8-trovo.svg"
                  className="btn-image"
                />
                <span className="home-text04">Trovo</span>
              </div>
              <div className="home-twitch-btn1 tab-title">
                <img
                  alt="image"
                  src="/icons8-twitch.svg"
                  className="btn-image"
                />
                <span className="home-text05">Twitch</span>
              </div>
              <div className="home-youtube-btn1 tab-title">
                <img
                  alt="image"
                  src="/icons8-youtube.svg"
                  className="btn-image"
                />
                <span className="home-text06">YouTube</span>
              </div>
              <div className="home-vkplay-btn1 tab-title">
                <img alt="image" src="/icons8-vk.svg" className="btn-image" />
                <span className="home-text07">VkPlay</span>
              </div>
            </div>
            <div className="home-container10 tab-contents">
              <div className="home-container11 tab-content">
                <iframe
                  src={process.env.REACT_APP_TROVO_IFRAME_CHAT_URL}
                  className="home-iframe4"
                ></iframe>
              </div>
              <div className="home-container12 tab-content">
                <iframe
                  src={process.env.REACT_APP_TWITCH_IFRAME_CHAT_URL}
                  loading="lazy"
                  className="home-iframe5"
                ></iframe>
              </div>
              <div className="home-container13 tab-content">
                <iframe
                  src={process.env.REACT_APP_YOUTUBE_IFRAME_CHAT_URL}
                  loading="lazy"
                  className="home-iframe6"
                ></iframe>
              </div>
              <div className="home-container14 tab-content">
                <span className="home-text08">
                  <span>Dont&apos;t work :(</span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="home-container16">
          <Script
            html={`<script>
  function initTabs() {
    // find all tabs containers
    const tabsContainers = document.getElementsByClassName("tabs");

    function selectContainer(tabContents, id) {
      Array.from(tabContents).forEach((tabContent, index) => {
        tabContent.style.display = id === index ? "flex" : "none";
      });
    }

    function selectTabTitle(tabTitles, id) {
      Array.from(tabTitles).forEach((tabTitle, index) => {
        tabTitle.classList.remove("tab-title-selected");
        // set the first tab content vile selected by default
        if (id === index) {
          tabTitle.classList.add("tab-title-selected");
        }
      });
    }

    // init state and events for each tabs system
    Array.from(tabsContainers).forEach((tabsContainer) => {
      // initialisation flag
      if (tabsContainer.classList.contains("initialised")) {
        return;
      }

      tabsContainer.classList.add("initialised");

      const tabTitles = tabsContainer.getElementsByClassName("tab-title");
      const tabContents = tabsContainer.getElementsByClassName("tab-content");

      // set the first tab content selected by default
      selectContainer(tabsContainer, 0);

      // set the first tabTitle selected by default
      selectTabTitle(tabTitles, 0);

      // add events
      Array.from(tabTitles).forEach((tabTitle, index) => {
        // show corresponding content on click
        tabTitle.addEventListener("click", () => {
          selectContainer(tabContents, index);
          selectTabTitle(tabTitles, index);
        });
      });
    });
  }

  initTabs();
</script>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

export default Home
